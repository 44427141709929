.inputGroup > label {
    color: #676767; 
    display:flex;
    padding-left: 205px;
    margin-bottom: 5px;
    text-transform: uppercase;  
  }

  .inputGroup > input {
    background: #f2f2f5;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    color: #676767;
    font-size: 16px;
    height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: all 0.3s;
    width: 36%;
    margin-bottom: 20px;
  }
  
  .registerBtn {
    background-color: var(--sds-c-button-brand-color-background, #0176d3);
    color: white;
    padding: 0.3rem 0.7rem;
    border-radius: 5px;
    border: none;
    width: 36%;  
  }


