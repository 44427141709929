.container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.panel {
  padding: 1rem;
  margin: 0.5rem;
}

.selectionPanel {
  width: 60%;
}

.previewPanel {
  width: 40%;
}

.formControl {
  margin-top: 1rem;
}

.messageItem {
  padding: 5px;
  cursor: pointer;
}

.optionBtn {
  background-color: var(--sds-c-button-brand-color-background, #0176d3);
  color: white;
  border-radius: 5px;
  border: none;
  width: 100px;
  height: 80px;
  margin: 10px;
}

.addActionBtn {
  background-color: var(--sds-c-button-brand-color-background, #0176d3);
  color: white;
  border-radius: 5px;
  border: none;
  width: 100px;
  height: 30px;
  margin: 10px;
}
.containerContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.inputGroup > label {
  color: #676767;
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;
}

.inputGroup > input, textarea, select {
  background: #f2f2f5;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  color: #676767;
  font-size: 16px;
  height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: all 0.3s;
  width: 99%;
  margin: 5px;
}

.inputGroup > label {
  text-align: left;
  margin: 10px;
  text-transform: inherit;
}


.inputActionGroup > input{
  background: #f2f2f5;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  color: #676767;
  font-size: 16px;
  height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: all 0.3s;
  width: 45%;
  margin: 5px;
}

.required:after { 
  content:" *"; 
  color: #ff0000;
} 

.toggleContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.toggleButton {
  padding: 0.5rem 1rem;
  background-color: #e0e0e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleButton:hover {
  background-color: #d5d5d5;
}

.active {
  background-color: #1589ee; /* Salesforce blue-inspired hue */
  color: #fff;
}

/* Message Card Styles */
.messageCard {
  background-color: #fff;
  border: 1px solid #d8dde6;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Header */
.cardHeader {
  background-color: #f4f6f9;
  padding: 1rem;
  border-bottom: 1px solid #d8dde6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardTitle {
  margin: 0;
  font-size: 1.25rem;
  color: #323130;
}

/* Language Selector in Header */
.languageSelector select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #c8c6c4;
  border-radius: 0.25rem;
  width: 20%;
}

/* Body */
.cardBody {
  padding: 1rem;
  font-size: 1rem;
  color: #605e5c;
}

.cardBody p {
  margin: 0 0 1rem 0;
}

/* Media Image */
/* Updated styling to respect original image size */
.cardImage {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 300px; /* Limits the image's height */
  width: auto;
  height: auto;
  object-fit: contain; /* Ensures the image scales without distortion */
}

/* Footer and Action Buttons */
.cardFooter {
  padding: 1rem;
  border-top: 1px solid #d8dde6;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.actionButton {
  background-color: #1589ee;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.actionButton:hover {
  background-color: #0f6ab4;
}