.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.content {
  max-width: 640px;
  text-align: center;
  width: 100%;
  margin-top: 5rem;
  
}
